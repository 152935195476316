import { classNames } from "@circle-react/helpers/twMergeWithCN";
import { Icon } from "@circle-react-shared/Icon";
import useAccordionContext from "./useAccordionContext";

interface AccordionItemProps {
  children: React.ReactNode;
  title: string;
  isDone?: boolean;
  showIcon?: boolean;
  className?: string;
  titleClassName?: string;
  contentClassName?: string;
}

export const AccordionItem = ({
  children,
  title,
  isDone,
  showIcon,
  className,
  titleClassName,
  contentClassName,
}: AccordionItemProps) => {
  const { onToggle, checkIfActive } = useAccordionContext();

  const isActive = checkIfActive(title);

  return (
    <div
      key={title}
      className={classNames("accordion__item", className, {
        "accordion__item--shown": isActive,
      })}
    >
      <button
        type="button"
        onClick={() => onToggle(title)}
        className={classNames("accordion__item-title", titleClassName)}
      >
        {showIcon ? (
          <div className="flex items-center">
            <Icon
              className="accordion__item-check-icon"
              size={16}
              type={isDone ? "success-circle" : "circle-empty"}
            />
            <span className="text-left">{title}</span>
          </div>
        ) : (
          <span className="text-left">{title}</span>
        )}
        <Icon
          type="cheveron-down"
          className={classNames("accordion__item-title-icon", {
            "accordion__item-title-icon--rotated": isActive,
          })}
        />
      </button>
      <div
        className={classNames(
          "accordion__item-content",
          {
            "accordion__item-content--shown": isActive,
          },
          contentClassName,
        )}
      >
        {children}
      </div>
    </div>
  );
};
